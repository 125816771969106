import React, { useState, useRef } from "react";
import "./products.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import prodImg from "../../assets/productImg.png";
import { BiRightArrowAlt } from "react-icons/bi";
import { BiLeftArrowAlt } from "react-icons/bi";

function Products() {
  const sliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  function updateSlide(index) {
    setSlideIndex(index);
  }

  const settings = {
    infiniteLoop: true,
    autoPlay: true,
    showArrows: false,
    showStatus: false,
    showThumbs: false,
    showIndicators: false,
    onChange: (index) => {
      updateSlide(index);
    },
    swipeable: false,
    selectedItem: parseInt(slideIndex),
  };

  const slideContent = (btnTitle, headerLine1, headerLine2, content) => {
    return (
      <div className="carDiv">
        <img src={prodImg} className="prodImg" />
        <div className="prodText">
          <div className="arrowBtns">
            <div>
              <div id="horizontalLine" />
              <p id="partApp">{btnTitle}</p>
            </div>
            <div>
              <button
                className="btns"
                onClick={() => setSlideIndex(slideIndex - 1)}
              >
                <BiLeftArrowAlt />
              </button>
              <button
                className="btns"
                onClick={() => setSlideIndex(slideIndex + 1)}
              >
                <BiRightArrowAlt />
              </button>
            </div>
          </div>
          <h3 className="prodHeader">{headerLine1}</h3>
          <h3 className="prodHeader">{headerLine2}</h3>
          <p className="prodCont">{content}</p>
          <div id="ctaBtns">
            <button id="ctaRight">
              <BiRightArrowAlt />
            </button>
            <p id="mreDets">More Details</p>
          </div>
        </div>
      </div>
    );
  };

  var step0Class = "slideStep";
  var step1Class = "slideStep";
  var step2Class = "slideStep";
  var step3Class = "slideStep";
  var step4Class = "slideStep";

  switch (slideIndex) {
    case 0:
      step0Class = "slideStep activeStep";
      break;
    case 1:
      step1Class = "slideStep activeStep";
      break;
    case 2:
      step2Class = "slideStep activeStep";
      break;
    case 3:
      step3Class = "slideStep activeStep";
      break;
    case 4:
      step4Class = "slideStep activeStep";
      break;
  }

  return (
    <div id="products">
      <div id="stepperDiv">
        <div id="stepDots">
          <span id="stepLine" />
          <span id="stepperContainer">
            <div className={step0Class} onClick={() => setSlideIndex(0)} />
            <div className={step1Class} onClick={() => setSlideIndex(1)} />
            <div className={step2Class} onClick={() => setSlideIndex(2)} />
            <div className={step3Class} onClick={() => setSlideIndex(3)} />
            <div className={step4Class} onClick={() => setSlideIndex(4)} />
          </span>
        </div>
      </div>
      <Carousel {...settings} ref={sliderRef}>
        {slideContent(
          "Partner App",
          "Referal and",
          "Channel Partner App",
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore.Lorem ipsum dolor sit amet,
          consectetur adipiscing eli. Lorem ipsum dolor sit amet,
          consectetur adipiscing eli.`
        )}
        {slideContent(
          "Procurement App",
          "Referal and",
          "Channel Partner App",
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore.Lorem ipsum dolor sit amet,
          consectetur adipiscing eli. Lorem ipsum dolor sit amet,
          consectetur adipiscing eli.`
        )}
        {slideContent(
          "Site-Engineer App",
          "Referal and",
          "Channel Partner App",
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore.Lorem ipsum dolor sit amet,
          consectetur adipiscing eli. Lorem ipsum dolor sit amet,
          consectetur adipiscing eli.`
        )}
        {slideContent(
          "Project Manager App",
          "Referal and",
          "Channel Partner App",
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore.Lorem ipsum dolor sit amet,
          consectetur adipiscing eli. Lorem ipsum dolor sit amet,
          consectetur adipiscing eli.`
        )}
      </Carousel>
    </div>
  );
}

export default Products;
