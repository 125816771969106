import React, { useRef, useState } from "react";
import logo from "../../assets/logo.png";
import "./Navbar.css";
import arrowIcon from "../../assets/rightArrow.png";
import menuIcon from "../../assets/menu.png";
import { slide as Menu } from "react-burger-menu";

import { CgClose } from "react-icons/cg";
import { CgChevronRightO } from "react-icons/cg";

function Navbar() {
  // console.log(nav.current);
  // function myFunction() {
  //   const x = document.getElementById("myTopnav");
  //   if (x.className === "topnav") {
  //     x.className += " responsive";
  //   } else {
  //     x.className = "topnav";
  //   }
  // }

  // const [respNav, setRespNav] = useState("topnav");
  const [burgerMenu, setBurgerMenu] = useState(false);

  const responsiveNav = () => {
    burgerMenu ? setBurgerMenu(false) : setBurgerMenu(true);
  };

  const nav = useRef(null);

  return (
    <div>
      <Menu
        isOpen={burgerMenu}
        onClose={() => setBurgerMenu(false)}
        itemListElement="div"
        right
        disableAutoFocus
        customCrossIcon={<CgClose />}
      >
        <a id="burgerLink" className="dropDn dividerDn">
          Products
          <CgChevronRightO className="rightCircle" />
          <a className="prodDropDn dividerSub">Partner App</a>
          <a className="prodDropDn dividerSub">Procurement App</a>
          <a className="prodDropDn">Project Management</a>
        </a>
        <a href="#services" id="burgerLink" className="dividerDn">
          Services
          <CgChevronRightO className="rightCircle" />
        </a>
        <a href="faq" id="burgerLink" className="dividerDn">
          FAQ's
          <CgChevronRightO className="rightCircle" />
        </a>
        <a id="burgerLink" className="dropDn">
          Products
          <CgChevronRightO className="rightCircle" />
          <a className="prodDropDn dividerSub">Partner App</a>
          <a className="prodDropDn dividerSub">Procurement App</a>
          <a className="prodDropDn dividerSub">Project Management</a>
        </a>
      </Menu>

      <div className="topnav" id="myTopnav" ref={nav}>
        <a href="/" style={{ display: "block", padding: "0px" }}>
          <img src={logo} alt="Cendrol IT" className="cendrolIt" />
        </a>
        <div className="navLinks">
          <div className="dropdown">
            <button className="dropbtn">Products</button>
            <div className="dropdown-content">
              <div className="dropDnContent">
                <p className="dropdownText">
                  <img src={arrowIcon} alt="Go to" className="rightArrow" />
                  Partner App
                </p>
                <p className="dropdownText">
                  Learn about our team, our mission, and how we are making a
                  difference.
                </p>
              </div>
              <div className="dropDnContent">
                <p className="dropdownText">
                  <img src={arrowIcon} alt="Go to" className="rightArrow" />
                  Procurement App
                </p>
                <p className="dropdownText">
                  Find answers in our FAQs or get in touch with our support
                  team.
                </p>
              </div>
              <div className="dropDnContent">
                <p className="dropdownText">
                  <img src={arrowIcon} alt="Go to" className="rightArrow" />
                  Project Manager
                </p>
                <p className="dropdownText">
                  Learn about our blogs, our mission, and how we are making a
                  difference.
                </p>
              </div>
            </div>
          </div>
          <a href="#news" className="navItems">
            Services
          </a>
          <a href="faq" className="navItems">
            FAQ's
          </a>
          <div className="dropdown">
            <button className="dropbtn">Company</button>
            <div className="dropdown-content">
              <div className="dropDnContent">
                <p className="dropdownText">
                  <img src={arrowIcon} alt="Go to" className="rightArrow" />
                  Know Us
                </p>
                <p className="dropdownText">
                  Learn about our team, our mission, and how we are making a
                  difference.
                </p>
              </div>
              <div className="dropDnContent">
                <p className="dropdownText">
                  <img src={arrowIcon} alt="Go to" className="rightArrow" />
                  Contact
                </p>
                <p className="dropdownText">
                  Find answers in our FAQs or get in touch with our support
                  team.
                </p>
              </div>
              <div className="dropDnContent">
                <p className="dropdownText">
                  <img src={arrowIcon} alt="Go to" className="rightArrow" />
                  Blogs
                </p>
                <p className="dropdownText">
                  Learn about our blogs, our mission, and how we are making a
                  difference.
                </p>
              </div>
              <div className="dropDnContent">
                <p className="dropdownText">
                  <img src={arrowIcon} alt="Go to" className="rightArrow" />
                  Careers
                </p>
                <p className="dropdownText">
                  Discover your dream role building the future of the Cendrol
                  Consulitng
                </p>
              </div>
            </div>
          </div>
        </div>
        <button className="navBtn">Contact Us</button>
        <a className="icon" onClick={responsiveNav}>
          <img src={menuIcon} alt="Menu" className="menu" />
        </a>
      </div>
    </div>
  );
}

export default Navbar;
