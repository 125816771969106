import React from "react";
import "./services.css";
import "./grid.css";
import { BiRightArrowAlt } from "react-icons/bi";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";

import cloudServices from "../../assets/grid-icons/cloudServices.png";
import digiMarketing from "../../assets/grid-icons/digiMarketing.png";
import itConsulting from "../../assets/grid-icons/itConsulting.png";
import managedServices from "../../assets/grid-icons/managedServices.png";
import mobAppDev from "../../assets/grid-icons/mobAppDev.png";
import uiUx from "../../assets/grid-icons/uiUx.png";
import webAppDev from "../../assets/grid-icons/webAppDev.png";
import webDev from "../../assets/grid-icons/webDev.png";

function gridDiv(icon, heading, bodyText, customClass) {
  const classNames = "grid-item " + customClass;
  return (
    <Fade bottom>
      <div className={classNames}>
        <div className="gridIconDiv">
          <img className="gridIcon" src={icon} />
        </div>
        <h5 className="gridHeader">{heading}</h5>
        <p className="gridBodyText">{bodyText}</p>
      </div>
    </Fade>
  );
}

function Services() {
  const tempBodyText =
    "Lorem ipsumdolor sitamet, consectetur adipiselit, sed do eiusmod tempor.";
  return (
    <div id="parentDiv">
      <div id="headingServices">
        <Slide left>
          <h4 id="serviceHeader">
            Services that lead
            <br /> the way to better business
          </h4>
        </Slide>
        <Slide right>
          <button id="reqServQ">
            Request Quote
            <BiRightArrowAlt id="rightArrow" />
          </button>
        </Slide>
      </div>
      <div id="gridParent">
        <div className="Grid-container-l">
          {gridDiv(webDev, "Website Development", tempBodyText, "leftTop")}
          {gridDiv(
            webAppDev,
            "Web Application Development",
            tempBodyText,
            "midTop"
          )}
          {gridDiv(mobAppDev, "Mobile App Development", tempBodyText, "midTop")}
          {gridDiv(
            digiMarketing,
            "Digital Marketing",
            tempBodyText,
            "rightTop"
          )}
          {gridDiv(uiUx, "UI/UX", tempBodyText, "leftBottom")}
          {gridDiv(itConsulting, "IT Consulting", tempBodyText, "midBottom")}
          {gridDiv(cloudServices, "Cloud Services", tempBodyText, "midBottom")}
          {gridDiv(
            managedServices,
            "Managed Services",
            tempBodyText,
            "rightBottom"
          )}
        </div>

        {/* Tablet Grid */}
        <div className="Grid-container-s">
          {gridDiv(webDev, "Website Development", tempBodyText, "tabletGrid")}
          {gridDiv(
            webAppDev,
            "Web Application Development",
            tempBodyText,
            "tabletGrid"
          )}
          {gridDiv(
            mobAppDev,
            "Mobile App Development",
            tempBodyText,
            "tabletGrid"
          )}
        </div>
      </div>
      <div id="tabBtns">
        <button id="reqServQ">
          Request Quote
          <BiRightArrowAlt id="rightArrow" />
        </button>
        <button id="viewAll">
          View All Services
        </button>
      </div>
    </div>
  );
}

export default Services;
