import React from "react";
import "./scrollgif.css";

function ScrollGif() {
  return (
    <div id="chevronContainer">
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
    </div>
  );
}

export default ScrollGif;
