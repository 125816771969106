import React, { useState, useRef } from "react";
import "./caseStudy.css";
import { BiRightArrowAlt } from "react-icons/bi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slide from "react-reveal/Slide";

import phoneImg from "../../assets/phone.png";
import phoneLappyImg from "../../assets/phone&laptop.png";

function CaseStudy() {
  const sliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);

  const responsive = {
    all: {
      breakpoint: { max: 14400, min: 0 },
      items: 2, // optional, default to 1.
    },
  };

  function cards(imgSource, projName, views, likes) {
    return (
      <div className="cardPadding">
        <div className="card">
          <div id="csImgDiv">
            <img className="cardImg" src={imgSource} />
          </div>
          <div className="projMets">
            <p className="metText">{projName}</p>
            <div className="viewsNlikes">
              <p className="metText">{views}</p>
              <p className="metText">{likes}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const settings = {
    swipeable: true,
    draggable: true,
    responsive: responsive,
    ssr: true, // means to render carousel on server-side.
    infinite: true,
    autoPlaySpeed: 2500,
    keyBoardControl: true,
    transitionDuration: 1000,
    containerClass: "carousel-container",
    removeArrowOnDeviceType: ["none"],
    deviceType: "bigScreen",
    dotListClass: "custom-dot-list-style",
  };

  return (
    <div>
      <div id="headingServices">
        <Slide left>
          <h4 id="serviceHeader">
            Case study that lead
            <br /> the way to better business
          </h4>
        </Slide>
        <Slide right>
          <button id="reqServQ">
            Request Quote
            <BiRightArrowAlt id="rightArrow" />
          </button>
        </Slide>
      </div>
      <div id="sliderParentDiv">
        <div id="slider">
          <Carousel {...settings} ref={sliderRef}>
            {cards(phoneImg, "Partner App", "6.5k", "5L")}
            {cards(phoneLappyImg, "Procurement App", "6.5k", "5L")}
            {cards(phoneImg, "Site Engineer App", "6.5k", "5L")}
            {cards(phoneLappyImg, "Project Manager App", "6.5k", "5L")}
          </Carousel>
        </div>
      </div>
      <div id="smCaseSrudy">
        {cards(phoneImg, "Partner App", "6.5k", "5L")}
        {cards(phoneLappyImg, "Procurement App", "6.5k", "5L")}
        {cards(phoneImg, "Site Engineer App", "6.5k", "5L")}
      </div>

      <div id="tabBtns">
        <button id="reqServQ">
          Request Quote
          <BiRightArrowAlt id="rightArrow" />
        </button>
        <button id="viewAll" style={{ backgroundColor: "#F2F2F2" }}>
          View All Services
        </button>
      </div>
    </div>
  );
}

export default CaseStudy;
