import React from "react";
import "./letsdiscuss.css";

function LetsDiscuss() {
  return (
    <div id="ldParentDiv">
      <div id="ldInnerDiv">
        <h4 id="serviceHeader">Let's Discuss For a Awesome Project</h4>
        <p className="descText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor
        </p>
        <button id="letsDiscussBtn">Let's Discuss</button>
      </div>
    </div>
  );
}

export default LetsDiscuss;
