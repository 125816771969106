import React from "react";
import "../App.css";
import Header from "./Header/Header";
import Products from "./Products/Products";
import Process from "./Process/Process";
import Navbar from "./Navbar/Navbar";
import bgVideo from "../assets/bgVid.MP4";
import Services from "./Services/Services";
import CaseStudy from "./CaseStudy/CaseStudy";
import LetsDiscuss from "./LetsDiscuss/LetsDiscuss";
import CustomerSayin from "./CustomersSayin/CustomerSayin";
import Footer from "./Footer/Footer";

function LandingPage() {
  return (
    <div className="App">
      <div id="header">
        <Navbar/>
        <video autoPlay muted loop id="bgVideo">
          <source src={bgVideo} type="video/mp4" />
        </video>
        <Header />
      </div>
      <Products />
      <Process />
      <Services />
      <CaseStudy />
      <LetsDiscuss />
      <CustomerSayin />
      <Footer />
    </div>
  );
}

export default LandingPage;
