import React, { useState, useRef } from "react";
import "./process.css";
import Slide from "react-reveal/Slide";

import { BiRightArrowAlt } from "react-icons/bi";
import eightyPerc from "../../assets/eightyPerc.png";
import hundredPerc from "../../assets/hundredPerc.png";
import hwWeWork from "../../assets/hwWeWork.png";
import tenyears from "../../assets/tenyears.png";

function Process() {
  return (
    <div>
      <Slide left>
        <div id="understandDiv" className="processDiv">
          <div>
            <h1 class="processText">UNDERSTAND</h1>
          </div>
          <div id="thickLine" />
        </div>
      </Slide>
      <Slide right>
        <div id="researchDiv" className="processDiv">
          <img src={hwWeWork} id="hwWeWork" />
          <h1 class="processText">RESEARCH</h1>
        </div>
      </Slide>
      <Slide left>
        <div id="designDiv" className="processDiv">
          <img src={eightyPerc} id="eightyPerc" />
          <h1 class="processText">DESIGN</h1>
        </div>
      </Slide>
      <Slide right>
        <div id="developDiv" className="processDiv">
          <h1 class="processText">DEVELOP</h1>
          <img src={tenyears} id="tenyears" />
        </div>
      </Slide>
      <div id="releaseDiv" className="processDiv">
        <Slide left>
          <div id="releaseTextImgDiv">
            <img src={hundredPerc} id="eightyPerc" />
            <h1 class="processText">RELEASE</h1>
          </div>
        </Slide>
        <Slide right>
          <div id="ptyp">
            <p class="processTextSm">Our Process Towards Your Problem</p>
            <p class="processTextXsm">
              Lorem ipsum dolor sit amet, consectetur adipiscingelit. Mattis
              risus pharetra volutpat, vestibulum auctor felis. Turpis rutrum
              libero pulvinar.
            </p>
            <button id="letsDiscuss">
              Let's Discuss{"  "}
              <BiRightArrowAlt />
            </button>
          </div>
        </Slide>
      </div>
    </div>
  );
}

export default Process;
