import React, { useState } from "react";
import "./customersSayin.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IconContext } from "react-icons";
import { BiRightArrowAlt } from "react-icons/bi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutdoorSE from "../../assets/OutdoorSE.png";
import CendrolBg from "../../assets/CendrolBg.png";

function CustomerSayin() {
  const [slideIndex, setSlideIndex] = useState(0);

  function updateSlide(index) {
    setSlideIndex(index);
  }

  const settings = {
    infiniteLoop: true,
    autoPlay: true,
    interval: 5000,
    centerMode: true,
    centerSlidePercentage: 75,
    showArrows: false,
    showStatus: false,
    showThumbs: false,
    showIndicators: false,
    onChange: (index) => {
      updateSlide(index);
    },
    swipeable: false,
    stopOnHover: true,
    swipeScrollTolerance: 2,
    selectedItem: parseInt(slideIndex),
  };
  function sayinCards(mainText, custTitle, custOrg, logo) {
    return (
      <div className="CustSayCard">
        <div className="custImgDiv">
          <img className="CustImg" src={OutdoorSE} alt="CustomerImg" />
        </div>
        <div className="textDiv">
          <p className="cmsMainText">{mainText}</p>
          <div className="custDets">
            <img className="CustLogo" src={logo} alt="Customer Logo" />
            <div className="custTitleOrg">
              <p className="custTitle">{custTitle}</p>
              <p className="custOrg">{custOrg}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h4 id="cmsHeader">What Our Customers are Saying..</h4>
      <div id="cmsFade">
        <Carousel {...settings}>
          {sayinCards(
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a
            curabitur integer tincidunt nunc ipsum eu massa rhoncus. Turpis nisl
            amet. `,
            "Cendrol Construction",
            "Managing Director",
            CendrolBg
          )}
          {sayinCards(
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a
            curabitur integer tincidunt nunc ipsum eu massa rhoncus. Turpis nisl
            amet. `,
            "Cendrol Construction",
            "Managing Director",
            CendrolBg
          )}
          {sayinCards(
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a
            curabitur integer tincidunt nunc ipsum eu massa rhoncus. Turpis nisl
            amet. `,
            "Cendrol Construction",
            "Managing Director",
            CendrolBg
          )}
        </Carousel>
      </div>
      <div id="nextNbackBtns">
        <button
          className="cmsBtns"
          onClick={() => setSlideIndex(slideIndex - 1)}
        >
          <IconContext.Provider value={{ size: "42" }}>
            <BiLeftArrowAlt />
          </IconContext.Provider>
        </button>
        <button
          className="cmsBtns"
          onClick={() => setSlideIndex(slideIndex + 1)}
        >
          <IconContext.Provider value={{ size: "42" }}>
            <BiRightArrowAlt />
          </IconContext.Provider>
        </button>
      </div>
    </div>
  );
}

export default CustomerSayin;
