import React from "react";
import "./footer.css";
import { CgChevronRightO } from "react-icons/cg";
import { FiPhoneCall } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import logo from "../../assets/logo.png";

import facebook from "../../assets/facebook.png";
import linkedin from "../../assets/linkedin.png";
import instagram from "../../assets/instagram.png";

function Footer() {
  return (
    <div id="footerDiv">
      {/* Footer for mobile */}
      <div className="mobileFooter">
        <img src={logo} alt="Cendrol IT" className="cendrolIt" />
        <div className="footerDropDn">
          <div className="footerDropDnLabels">
            <a className="dropDnText about">About Cendrol Technologies</a>
            <CgChevronRightO className="footerRightCircle" />
          </div>
          <div id="aboutDropDn">
            <p className="dropDnSubText">About Us</p>
            <p className="dropDnSubText">Blogs</p>
            <p className="dropDnSubText">Careers</p>
          </div>
        </div>
        <div className="footerDropDn">
          <div className="footerDropDnLabels">
            <a className="dropDnText faq">FAQ's</a>
            <CgChevronRightO className="footerRightCircle" />
          </div>
          <div id="faqDropDn">
            <p className="dropDnSubText">How it works</p>
            <p className="dropDnSubText">Privacy Policy</p>
            <p className="dropDnSubText">Terms {"&"} Conditions</p>
          </div>
        </div>
        <div className="socialThumbs">
          <img src={linkedin} className="socialImg" />
          <img src={facebook} className="socialImg" />
          <img src={instagram} className="socialImg" />
        </div>

        <div id="phNmail">
          <div className="ContactDiv">
            <div className="contactIcons">
              <FiPhoneCall style={{ color: "white" }} />
            </div>
            <div className="contactInfoDiv">
              <p className="contactTitle">Phone</p>
              <p className="contactInfo">310-437-2766</p>
            </div>
          </div>

          <div className="ContactDiv">
            <div className="contactIcons">
              <FiMail style={{ color: "white" }} />
            </div>
            <div className="contactInfoDiv">
              <p className="contactTitle">Mail</p>
              <p className="contactInfo">sales@cendrol.com</p>
            </div>
          </div>
        </div>

        <div className="ContactDiv">
          <div className="contactIcons">
            <GoLocation style={{ color: "white" }} />
          </div>
          <div className="contactInfoDiv">
            <p className="contactTitle">Address</p>
            <p className="contactInfo">706 Campfire Ave. Meriden, CT 06450</p>
          </div>
        </div>
      </div>

      {/* Footer for big screens */}
      <div id="largeFooter">
        <div>
          <img src={logo} alt="Cendrol IT" className="cendrolIt" />
          <div id="contactInfoL">
            <div className="ContactDiv">
              <div className="contactIcons">
                <FiPhoneCall style={{ color: "white" }} />
              </div>
              <div className="contactInfoDiv">
                <p className="contactTitleL">Phone</p>
                <p className="contactInfoL">310-437-2766</p>
              </div>
            </div>

            <div className="ContactDiv">
              <div className="contactIcons">
                <FiMail style={{ color: "white" }} />
              </div>
              <div className="contactInfoDiv">
                <p className="contactTitleL">Mail</p>
                <p className="contactInfoL">sales@cendrol.com</p>
              </div>
            </div>

            <div className="ContactDiv">
              <div className="contactIcons">
                <GoLocation style={{ color: "white" }} />
              </div>
              <div className="contactInfoDiv">
                <p className="contactTitleL">Address</p>
                <p className="contactInfoL">
                  706 Campfire Ave. Meriden, CT 06450
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="footerLinksNThumb">
          <div id="footerLinksL">
            <div className="linksDivL">
              <h6 className="footerLinksHeaderL">About</h6>
              <p className="footerLinksL">About Us</p>
              <p className="footerLinksL">Blog</p>
              <p className="footerLinksL">Careers</p>
            </div>
            <div className="linksDivL">
              <h6 className="footerLinksHeaderL">FAQ</h6>
              <p className="footerLinksL">How it works</p>
              <p className="footerLinksL">Privacy Policy</p>
              <p className="footerLinksL">Terms {"&"} Conditions</p>
            </div>
          </div>
          <div className="socialThumbsL">
            <img src={linkedin} className="socialImgL" />
            <img src={facebook} className="socialImgL" />
            <img src={instagram} className="socialImgL" />
          </div>
        </div>
      </div>
      <div id="footerBottom">
        <p id="copyRight">© 2022-2022, All Rights Reserved</p>
      </div>
    </div>
  );
}

export default Footer;
