import React from "react";
import "./faq.css";
import Navbar from "../Navbar/Navbar";
import headImg from "../../assets/faqHeaderImage.png";
import Footer from "../Footer/Footer";
import gettingStarted from "../../assets/faqCatImg/gettingStarted.png";
import pricingNplans from "../../assets/faqCatImg/pricingNplans.png";
import privacyPolicy from "../../assets/faqCatImg/privacyPolicy.png";
import salesQs from "../../assets/faqCatImg/salesQs.png";
import termsNcond from "../../assets/faqCatImg/terms&cond.png";
import transparency from "../../assets/faqCatImg/transparency.png";

function catBox(imgSrc, title) {
  return (
    <div className="catBox">
      <img src={imgSrc} className="catBoxImg" />
      <p className="catBoxTitle">{title}</p>
    </div>
  );
}

function Faq() {
  return (
    <div>
      <div id="navBg">
        <Navbar />
      </div>
      <div id="faqheadDiv">
        <img id="headImg" src={headImg} />
        <div id="faqHeading">
          <h4 id="faqHeadingText">
            Hi, We're <br />
            Here To Help You
          </h4>
        </div>
      </div>
      <div id="faqContentDiv">
        <h4 id="catText">Categories</h4>
        <div id="catBoxes">
          {catBox(gettingStarted, "Getting Started")}
          {catBox(pricingNplans, "Pricing & Plans")}
          {catBox(privacyPolicy, "Privacy Policy")}
          {catBox(salesQs, "Sales")}
          {catBox(termsNcond, "Terms & Conditions")}
          {catBox(transparency, "Transparency")}
        </div>
        <div>
          <div className="catFaqCont">
            <h4 className="catHeading">Pricing {"&"} Plans</h4>
            <div className="yellowLine" />
            <p className="subText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
              consectetur adipiscing elit. Malesuada nunc, neque vel aliquet.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
