import React from "react";
import "./header.css";
import ScrollGif from "../ScrollGif/ScrollGif";
import { BiRightArrowAlt } from "react-icons/bi";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

function Header() {
  return (
    <div id="headerDiv">
      <Zoom>
        <div id="headingDiv">
          <h1 id="headingCreative">Creative</h1>
          <h1 id="heading">Digital Agency</h1>
        </div>
      </Zoom>
      <Fade bottom>
        <p id="headerText">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore.Lorem ipsum dolor sit amet,
          consectetur adipiscing eli.
        </p>
        <button id="reqQ">
          Request Quote
          <BiRightArrowAlt id="rightArrow" />
        </button>
      </Fade>
      <ScrollGif />
    </div>
  );
}

export default Header;
